import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import I18nextContext from '../../context/I18nextContext';
import { Link as GatsbyLink } from 'gatsby';
import _ from 'lodash';

const Link = (props) => {
  const i18next = useContext(I18nextContext);
  const path = props.to;

  let currentLanguage = i18next.lng === 'zh' ? '' : `/${i18next.lng}`;

  if (props.language !== null) {
    currentLanguage = props.language === 'zh' ? '' : `/${props.language}`;
  }

  let linkProps = { ...props };

  linkProps = _.omit(linkProps, ['language', 'buttonColor', 'buttonBgColor']);

  const isExternalLink = () => {
    if (typeof window !== `undefined`) {
      let url = window.decodeURIComponent(path);

      if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        return true;
      }
    }

    return false;
  };

  if (path === '' || path === null) {
    return <div>{props.children}</div>;
  }

  const addSlash = path.slice(-1) === '/' ? '' : '/';

  return isExternalLink() ? (
    <a href={path} className={props.className} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <GatsbyLink {...linkProps} to={`${currentLanguage}${path}${addSlash}`}>
      {props.children}
    </GatsbyLink>
  );
};

export default Link;

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  language: null,
};
