import React from 'react';
import Link from '../Link';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Img from 'gatsby-image';

import { StyledSubList, StarProductImage } from './StyledSubList';
import { onCollapseClick } from '../../state/reducers/products';
import _ from 'lodash';
import { orderBySequence } from '../../utils/common';

const SubList = ({ parent, lists, className, starProduct, genderPath }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locale, subType } = useSelector((state) => state.common);
  const getLocaleName = (item) => {
    return item.localizations[locale].name;
  };

  return (
    <div className={className} style={{ display: 'none' }}>
      <StyledSubList className={`row no-gutters `}>
        {subType.map((item, index) => {
          return (
            <div className={`col list-container`} key={index}>
              <h5>{t(item.navigationName)}</h5>
              <ul>
                {orderBySequence(lists, item.key).map((list, j) => {
                  return (
                    <li key={j}>
                      <Link
                        onClick={() => dispatch(onCollapseClick(index))}
                        to={`${parent}/${list.slug}`}
                      >
                        {getLocaleName(list)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
        {starProduct && <div className={`star-product`}>
          <div className={`header`}>{t('star_product')}</div>
          <div className={`row no-gutters star-product__container`}>
            <div className="col">
              <StarProductImage>
                <Img
                  fluid={starProduct.remoteImage.childImageSharp.fluid}
                  alt={starProduct.localizations[locale].name}
                />
              </StarProductImage>
            </div>
            <div className="col-7 star-product__detail">
              <div
                className={`star-product__name`}
                dangerouslySetInnerHTML={{
                  __html: starProduct.localizations[locale].name,
                }}
              />
              <div
                className={`star-product__desc`}
                dangerouslySetInnerHTML={{
                  __html: starProduct.localizations[locale].star_description,
                }}
              />

              <Link
                to={`${genderPath}/product/${starProduct.slug}`}
                className={`star-product__link`}
              >
                {t('learn_more')}
              </Link>
            </div>
          </div>
        </div>}
      </StyledSubList>
    </div>
  );
};

export default SubList;
