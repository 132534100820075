import React, { useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';

import Link from '../Link';

import Floating from '../Floating/Floating';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  text-align:right;
`;

const Bar = styled.div`
  width: 100vw;
  background:black;
  text-align:left;
  padding:2rem;
`;

const Title = styled.h4`
  background-color: #000000;
  font-weight: bold;
  color: #23bbd2;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 0.95rem;
  margin-bottom: 1rem;

  a {
    color: #23bbd2;
    border-bottom: 1px dashed #ffffff;
    transition: all 0.3s;

    &:hover {
      color: #ffffff;
      text-decoration: none;
      border-color: #ee4b5a;
    }
  }
`;

const Button = styled.div`
  cursor: pointer;
  background-color: #23bbd2;
  color: #ffffff;
  display: table;
  margin-left: auto;
  margin-right: 0;
  border-radius: 4px;
  padding: 5px 10px;
`;

const CookiesBar = () => {
  const [cookies, setCookie] = useCookies(['acceptCookie']);
  const [isAccept, setIsAccept] = useState(false);

  function onCookiesClick() {
    setCookie('acceptCookie', 1, { path: '/', secure: true, sameSite: 'lax' });
    setIsAccept(true);
  }

  return (
    <>
      
        <Container>
          <Floating />
          {cookies.acceptCookie !== '1' && !isAccept && (
          <Bar>
          <Title>我們的Cookie政策</Title>
          <Text>
            我們利用cookies以爲您提供最佳的瀏覽體驗、進行數據分析及根據您對本網站和第三方網站的喜好展示資訊。若您選擇繼續瀏覽本網站，即表示您同意我們使用Cookies。
            <Link to="/cookie">更多資訊</Link>
          </Text>
          <Button onClick={onCookiesClick}>接受Cookies</Button>
          </Bar>
          )}
        </Container>
    </>
  );
};

export default CookiesBar;
