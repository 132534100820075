import styled, { css } from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';
import Link from '../Link';

export const Container = styled.div`
  ${(props) =>
    props.theme.name === 'men'
      ? 'border-top: 1px solid #555555;'
      : `background-color: ${props.theme.primaryColor}`}
`;

export const Box = styled.div`
  ${(props) =>
    props.theme.name === 'men'
      ? css`
          border-left: 1px dotted #252525;
        `
      : css`
          max-width: 23%;
          flex: 0 0 23%;
        `}
`;

export const BoxContent = styled.div`
  ${(props) =>
    props.theme.name === 'men' &&
    css`
      width: 70%;
      margin: 0 auto;
    `}
`;

export const Header = styled.div`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  font-size: ${(props) => (props.theme.name === 'men' ? '16px' : '18px')};
  color: ${(props) => (props.theme.name === 'men' ? '#bfbfbf' : '#ffffff')};
  font-weight: bold;
  text-transform: ${(props) =>
    props.theme.name === 'men' ? 'uppercase' : 'capitalize'};
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.theme.name === 'men' ? 'transparent' : '#ffffff'};
`;

export const FooterLink = styled(Link)`
  color: ${(props) => (props.theme.name === 'men' ? '#878787' : '#ffffff')};
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.headerColor};
    text-decoration: none;
  }
`;

export const FooterHref = styled.a`
  color: ${(props) => (props.theme.name === 'men' ? '#878787' : '#ffffff')};
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.headerColor};
    text-decoration: none;
  }
`;

export const BottomContainer = styled.div`
  ${mediaQueries('md')} {
    padding: 10px;
  }
`;

export const BottonLinks = styled.div`
  a {
    margin-right: 10px;
    display: inline-block;
    text-align: left;

    ${mediaQueries('md')} {
      margin-right: 5px;

      &:not(:last-child)::after {
        content: '/';
        padding-left: 5px;
      }
    }
  }
`;

export const FooterSubLink = styled(Link)`
  color: ${(props) => (props.theme.name === 'men' ? ' #878787' : '#535355')};
  transition: color 0.3s;
  font-size: ${(props) => (props.theme.name === 'men' ? '14px' : '13px')};

  @media screen and (min-width: 768px) {
    &:not(:last-child) {
      ${(props) =>
    props.theme.name === 'men' &&
    css`
          margin-right: 0.5rem;
          padding-right: 0.5rem;
          border-right: 1px solid #878787;
        `}
    }
  }

  ${mediaQueries('md')} {
    font-size: 12px;
    ${(props) => props.theme.name === 'men' && 'color: #db0000;'}
  }

  &:hover {
    color: ${(props) => props.theme.activeColor};
    text-decoration: none;
  }
`;

export const Tnc = styled.span`
  color: ${(props) => (props.theme.name === 'men' ? ' #878787' : '#535355')};

  ${mediaQueries('md')} {
    ${(props) => props.theme.name === 'men' && 'color: #ff0000;'}
  }
`;
