import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../Link';

import {
  Container
} from './StyledFloating';

import FloatingImageZH from '../../assets/images/floating/Bio-WhatsApp-Landing-Icon-zh-500x216.png';
import FloatingImageEN from '../../assets/images/floating/Bio-WhatsApp-Landing-Icon-eng.png';

const Floating = () => {

  const { i18n } = useTranslation();

  return (
    <Container>
      <Link to={i18n.language == 'en'? 'https://apps.onmessenger.com/bio-hk/en/?t=website':'https://apps.onmessenger.com/bio-hk/zh-Hant/?t=website'}>
        <img src={i18n.language == 'en' ? FloatingImageEN:FloatingImageZH} alt="Floating" />
      </Link>
    </Container>
  );
};

export default Floating;
