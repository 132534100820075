import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';

import LayoutContext from '../../context/LayoutContext';
import Link from '../Link';
import SNSLInk from './SNSLink';
import * as styles from './TopBar.module.scss';

const useSnsData = () => {
  const { allSnsJson } = useStaticQuery(graphql`
    query {
      allSnsJson {
        edges {
          node {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 26, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            path
          }
        }
      }
    }
  `);
  return allSnsJson.edges;
};

const Container = styled.div`
  background-color: ${(props) => props.theme.headerBgColor};
  padding: 5px;
  border-bottom: 1px solid #ffffff;

  ${mediaQueries('md')} {
    padding: 5px 12px;
    border-bottom: none;
  }
`;

const TopBar = () => {
  const layoutProps = useContext(LayoutContext);
  const { locale, isMobileView } = useSelector((state) => state.common);
  const { t } = useTranslation();

  const snsData = useSnsData();

  let language = 'en';
  let originalPath = '/';

  try {
    language = locale === 'zh' ? 'en' : 'zh';
    originalPath = layoutProps.pageContext.originalPath;
  } catch (error) {}

  return (
    <>
      {!isMobileView ? (
        <Container className={`row no-gutters justify-content-between`}>
          <div>
            <Link className={styles.Link} to="/store-locator">
              {t('store_location')}
            </Link>
            <Link className={styles.Link} to="/contact-us">
              {t('contact_us')}
            </Link>
            <Link className={styles.Link} to={originalPath} language={language}>
              {t('header_locale')}
            </Link>
          </div>
          <div className={`row no-gutters`}>
            {snsData.map(({ node }) => (
              <SNSLInk
                key={node.id}
                path={node.path}
                image={node.image.childImageSharp.fluid}
              />
            ))}
          </div>
        </Container>
      ) : (
        <Container className={`row no-gutters justify-content-end`}>
          <Link className={styles.Link} to={originalPath} language={language}>
            {t('header_locale')}
          </Link>
        </Container>
      )}
    </>
  );
};

export default TopBar;
