import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { StyledLink, StyledStaticSubList } from './StyledSubList';

const StaticSubList = ({ className, genderPath, lists }) => {
  const { t } = useTranslation();
  const { locale } = useSelector((state) => state.common);

  return (
    <StyledStaticSubList>
      {lists.map((list, index) => {
        const itemlink = locale === 'en' ? list.pathEN : list.path;
        const listlink = itemlink.includes('http') ? itemlink : `${genderPath}${itemlink}`;

        return (
          <StyledLink
            key={index}
            to={listlink}
            className="p-1"
          >
            <span>{t(list.name)}</span>
          </StyledLink>
        );
      })}
    </StyledStaticSubList>
  );
};

export default StaticSubList;
